import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`« Il était une fois un directeur de grand hôtel soucieux d’améliorer la marche de son entreprise. Il fit établir diverses statistiques dans un tableau de chiffres qui resta de nombreux jours sur son bureau.»`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.41717791411043%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACa0lEQVQ4y0WT627aQBCFV01omlSqIlVtXqLP2Yfom/RXIf0RqpBwicGAbfD9ws02EFwnK03nTEpiabVr7+y3c86M1ePjIy2XS+re39NisZDR6XRoMBjQaDQiy7Ko1+uRYQzJNE2657jhcCjfJ5OJdmYz8m3npzo+L0FdarfblGULKsuSHMehMAxlOM6MXNelKIzku+f5NJ/PacYgd+5qLwgoMEYtRl2ok3dfVb/fp2azRd1uj6rqL63Xawn2PI+iKJLDvu9TkiTkuZ6ssYdLkjjWKSvy73q/GXimLj+dKci6ve1QzAfqmoGrlWQCaBzHchjrIAh5BAJE5oEfUJqmOs0ymt91rxnYUBcfPiocNs2xeGLb9os8ziyJE1oxPOMDgOAbA8RjrAHltc7ZomhotsS/z5fvxcObmzZ7+IemU0sCj1AchtQkiQWM4mHGXvYC10u2yO8byPCUMzwXyQ8PBu33e8KD2WXfAEKGkI0LIPmYWRTFXKSQ0iTVKwAHRuu1ypCK4LquBVhVlVR6t9vR4XCg7XYr4E2eU5EXlPOMPcRwy+mn52eKJ9YbEKZb7B3k1PWTQDabDYN2fHBPZVG+QgAvy63MgLMaXTNw6cwFeHL1paEmkzE3sCnAkCUBCACgaHpkglEUhVwCGGxBXHWo9JPWtLBnTeadq8bp1WvbGOzjkqUhEMYDiKwABwxQgLAuipzfC+zrHV8ajsZvkmf/2wZ/CR4A0R7wDRBkjnc0/DHz9XojHcBwveNL4vH0WmA/viuFPwGSbcuW4JwHGhjVBRTVRmXxjt5MkpTXCcXSl5lGsdKp/Qu8b+uR+gfsnWDcv5JXlwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "BertinTablelegendes",
          "title": "BertinTablelegendes",
          "src": "/static/d0ba15f3c22a568b599afb7ab9bc57fe/a6d36/BertinTablelegendes.png",
          "srcSet": ["/static/d0ba15f3c22a568b599afb7ab9bc57fe/222b7/BertinTablelegendes.png 163w", "/static/d0ba15f3c22a568b599afb7ab9bc57fe/ff46a/BertinTablelegendes.png 325w", "/static/d0ba15f3c22a568b599afb7ab9bc57fe/a6d36/BertinTablelegendes.png 650w", "/static/d0ba15f3c22a568b599afb7ab9bc57fe/e548f/BertinTablelegendes.png 975w", "/static/d0ba15f3c22a568b599afb7ab9bc57fe/5f6dd/BertinTablelegendes.png 1139w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`« Puis un matin le secrétaire présenta une `}<strong parentName="p">{`image`}</strong>{` construite d’après les chiffres du tableau. Après quelques instants d’attention le directeur fit appeler ses collaborateurs et avec eux: `}</p>
    <ul>
      <li parentName="ul">{`il définit une nouvelle politique de prix`}</li>
      <li parentName="ul">{`il modifia les services offerts à la clientèle`}</li>
      <li parentName="ul">{`il ajusta les stocks`}</li>
      <li parentName="ul">{`il modifia le système de promotion-vente`}</li>
      <li parentName="ul">{`il alla voir le maire au sujet des dates des foires organisées dans la ville`}</li>
    </ul>
    <p>{`Les résultats obtenus lui assurèrent une rapide promotion. »`}</p>
    <h2>{`Quelle est donc cette `}<em parentName="h2">{`image`}</em>{` miraculeuse ?`}</h2>
    <p>{`En voici le premier élément:`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.32515337423312%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAADOklEQVQ4y2WTW0iTYRzGvxU2myFZildGV16IFmV65fJCQTAwFJcaXUVDoYsg9EZE0cBBUnfmRSZlYp6Gtk3nMU08gOagzZmim9s+d07ysJP68T297+uhone8/Pd9358fz/N/n5c7ODgAXaIo/rvJj3c4YDIaYSR7eXkZq6urWF9fh23TJvI8LzhsdtgslmyOLII4RytnMBhYs8lkYtt4AjCajBgZHoZarUZPdzd0Wh3Gxsag0WigamwU6+vrhYmpKbz91CmnHEVRURQpUdz29jYCgQD29vZYDUfCCIfDODw8hNVqRVNTE1paWlBdXY1uAqYrMzNTlEVHCysjE3B97CshoJjY+PgkIvEyJwgCTtf+/j7WVteItTVmbW5uDqUlJairqwN1lZGRwfpycnJE8iy8qKwCLHwOVWj+8vU8sxyJRM5mSFUFg0EEA0Gmko6iuLgYDQ0NDJiVlcV676SnM2DN80rsv/nwiHKynyqjpJxEyv05FPy33G4X2tra0N7ejtTUVOTl5bH3zc3N4gOFQhgdH0coEMglvItXEhKuc9TyKZBad7tc8Hg88Pl8cG454XDwoN9VKhVTSKyyXjJPsaKigs3KarHI/znlvy27XE64PW4G5PktOJ0uhEIh1NbWMmBaWhrrTUxMFJOSkhhwxvCNAR8rn1wgRcIdHR2xpt3dXfi8Puz82oHf/5PAXTCbzVhaWmK2q6qq0NnZCa/Xi5qaGrGOxMaysoLel6/zCSjhUkzMDVKvcgsLCyx309PTGBocZFnTarRQ96mh0+mgH9ZjSD+EYZLJ0dFRVvV6vTig1Qj9be8xqHx2jypMjItLYApp/qhtOqvTCNGTNn43YYvYpv9pDlNSUqBUKtn3ivJy8ebtW0JpwX28KnpYQECymOjoa6TGcjTMFERnSKG2TSs2NjZgJnb8fj9VA6lUCplMhtzcXNaTn5/PYkNuC+XfpQrtvOP4UE4jQnMXCobgsNthJ3f0B7m3VP3MzAySk5MhkUjOgi2XyxnwXWvrGdDr8RwDFxcXMT8/j66uLvT29EL7WYP+gQH0ncxQq9VicnISZWVlKCwsZL0KhUIkW5idnUVHRwcDkj4G/A0Hq/k6RTGfjQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PremierHotel",
          "title": "PremierHotel",
          "src": "/static/73e0d7dc3161e59ba59775374e6ae735/a6d36/PremierHotel.png",
          "srcSet": ["/static/73e0d7dc3161e59ba59775374e6ae735/222b7/PremierHotel.png 163w", "/static/73e0d7dc3161e59ba59775374e6ae735/ff46a/PremierHotel.png 325w", "/static/73e0d7dc3161e59ba59775374e6ae735/a6d36/PremierHotel.png 650w", "/static/73e0d7dc3161e59ba59775374e6ae735/50383/PremierHotel.png 740w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
Dans la partie supérieure, 3 indicateurs clés de l’activité:
    <ul>
      <li parentName="ul">{`le taux moyen d’occupation de l’hôtel (entre 55 et 92%)`}</li>
      <li parentName="ul">{`la durée moyenne de séjour (1,4 à 2 nuits)`}</li>
      <li parentName="ul">{`le prix moyen des chambres (entre 145 et 175€)`}</li>
    </ul>
    <p>{`qui conditionnent à la fois le chiffre d’affaires (% occupation, prix des chambres) et la rentabilité (durée du séjour, prix des chambres).`}</p>
    <br />
    <p>{`Dans sa partie inférieure 3 indicateurs externes :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`la présence de foires dans le mois`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`le pourcentage de clientèle business (entre 70 et 90% du total)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`le taux de réservation en provenance des agences de voyage (entre 15 et 30%)`}</p>
        <br />
      </li>
    </ul>
    <p>{`Les barres graphiques se lisent en ligne:`}</p>
    <ul>
      <li parentName="ul">{`la hauteur de la barre est proportionnelle à la valeur de la donnée`}</li>
      <li parentName="ul">{`la barre est coloriée en noir si la valeur est supérieure à la moyenne annuelle, en blanc sinon`}</li>
      <li parentName="ul">{`les foires sont codées par un carré noir`}</li>
      <li parentName="ul">{`les prix sont synthétisés en deux catégories: supérieur et inférieur à la moyenne (155€)`}</li>
    </ul>
    <h2>{`Que peut on lire dans cette image ?`}</h2>
    <p>{`Les objectifs du directeur sont probablement:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`maximiser`}</strong>{` le remplissage de l’hôtel au meilleur prix`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`minimiser`}</strong>{` les périodes creuses`}</p>
        <br />
      </li>
    </ul>
    <p>{`En ayant ces objectifs en tête, il est possible de remarquer que: `}</p>
    <br />
    <p>{`1- les trois facteurs externes choisis participent positivement au taux de remplissage :`}</p>
    <ul>
      <li parentName="ul">{`les foires hormis pour celle de décembre (d’où la visite chez le maire pour en discuter: sujet, date, …)`}</li>
      <li parentName="ul">{`la forte proportion d’hommes d’affaire. Qui elle même peut être la conséquence des foires ?`}</li>
      <li parentName="ul">{`le taux de réservation par agences de voyage. Lui aussi possiblement conséquence plutôt que cause des deux autres indicateurs. `}</li>
    </ul>
    <br />
    <p>{`2- Un autre constat est la mise en évidence de 2 types de périodes:`}</p>
    <ul>
      <li parentName="ul">{`Haute Saison (Printemps - Automne) : clientèle d'affaires et foires, avec à la fois une haute occupation et une durée de séjour élevée`}</li>
      <li parentName="ul">{`Basse Saison (Juillet/Aout et Hiver) : clientèle plus touristique et durée de séjour plus faible`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "298px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "95.0920245398773%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsSAAALEgHS3X78AAAEZ0lEQVQ4y4VUaUxcVRR+iTFGjbSlWlxKsHUpyXRYZF9a0NCKEoO2VqsmxmiMpYmGov2hTRSlwa3hR8XGhlogFAvIsDrADMsMDJStM8wCM1OG2ZhhGZYB2R5D4X7edxWUVONJvpx33n3ne+d857zHORyO/W63e7/L5fpXCGcABOxbKqqIWrM7D2Bp5cmZ/MJon9319OyPRTE+YO+itCV0prA8lFtZWaExfISQOyDcX19f91mGh30ms8mnbVWumQ1Dvlsmk8+l1a85LCO+ke6+NbfHs2oZ0G3YNFoPZzQaYbfbYbPZtsNqZd5isUBSWQlJlQSlF/MhKStHSVExaq8W4XpJCSou/YTi0lJcL/gZ1/IvzXGDg4NkZGSE0EQG4dpqtW55ARqNhpRXVJCOmnpyrbCI2K02QqslLoeDvJd+jNx9370bZVeuYuSmZpbb2NjA/1l/Xx9i4mIhLSnFHn9/xMXG4nLuN+zsWNKz4DiORIsOwqkf9HJLS0vsgGrF/KjDCbVag96eXvT29kGjvon2DhUSEhOhkFQLyQyffZAhJOFkyvOMMNB/N9pr673CUBgRHQLDf1UYEhaG1l8lW4Tnz3zCztITDzPCgPsfQH9z63bCTdsk37xHdUbG6dPoaWhCUkICAh/bi+yPMtlZbtZZJB85Qt5KfwW6js47KxQ0FbAZC1LMzMxgkUqjqq3HweBgPLT7QVrhxyzvRr0UX5zPIVa9AauTU38TCvb7/DxUKhXoVGEw6DEwoEVPdy9sdK28c3OoLy7ZavlLoUL64pcPJbGWO2VyuAaNfw5lszLB/lnd5rCEChcWFyEvq9g+FGovxScyQo2qExPmYe+2tRkdHYXb5aYYw/j4OG6Zh9li9/f3o4+i6ZdyXMzLQ7WkCuauHrgdDlz59nt89XUu6Whswo3GJi83QNszm81MeIVCAUWbAkqFknm5XI62v3yjTAZlVQ10ajX0Oh26f2uATCqFprkVOqOR9Cvb0SVt9HImSkZ/EOzzoz8ChrGxMYw6nWhpaUNDQyNMJhPGJybQRQcQHx2DyIgI/JCdg7npaZx9931ExMaQowmJSEs45OWWl5e3tBP0Yri9jjWfj2o3y1r3eDzQGww49dpJhIpE2BcUhLxznwP0uVMnXsejjweRuJBQXP7uAiMU1N8Cz68QLf12NWo1GdDqCNWPLCwskuaWFiKIfxeF4LM/zGTPn3guRYg3hMXWq7pmOZ5fZRXO05WZmprC9NQ0xmnLk5OTrH0nbX121suG8mZqGg7HxbMp52Rmsbw3jqay+HjqC9AqO+a4oaEh3m638XKZnJdUSvi62jq+rqaWl0qlfFVVNV9TXcMrlUq+pbWVN7SrVgsLCvhnwsP5C5+eW9WrNXzW2++sxiclrcgk1bc76qUTnJ+fn1iASCQSR0VHiSOjIsURkZHisLBQGlMfHiZ+JCBAvPPhAFH6PTuTIgKDwl99MS0kIyg4+Ykdu8LPPBWSHHY8/UDKroC49B17Yv8A/F5uGaPAQFAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Saisons",
          "title": "Saisons",
          "src": "/static/5c33f7e5a740f42cffb8716cb5cb2972/ca501/Saisons.png",
          "srcSet": ["/static/5c33f7e5a740f42cffb8716cb5cb2972/222b7/Saisons.png 163w", "/static/5c33f7e5a740f42cffb8716cb5cb2972/ca501/Saisons.png 298w"],
          "sizes": "(max-width: 298px) 100vw, 298px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`3- Le prix moyen semble ne pas du tout participer à l’optimisation du remplissage et de la rentabilité. Il serait plus logique de maintenir des prix hauts durant les périodes de forte occupation (clientèle business) et d’inciter les séjours touristiques durant les périodes creuses avec des prix plus bas. Une nouvelle politique de prix s'impose.`}</p>
    <h2>{`Remplir les périodes creuses`}</h2>
    <p>{`Pour savoir comment cibler efficacement des promotions dans le temps il faut comprendre la composition de la clientèle dans chaque période. Selon le même procédé d’affichage de mise en évidence des valeurs au dessus et en dessous de la moyenne, il suffit de regrouper les lignes de la table selon leur similarité visuelle:`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.43558282208589%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAAD9klEQVQ4y1VU20vcRxjdN0HY+Jr/QcyDICa+SOOLKQjSSvsgoe1DkBYNxFuhqxFqbCUmajGBsoLb4kOy6hbr3dR6q2K87EV3ve1N3Yu6s/Wyq+uq4+zpN2OsZuDjNzO/mTPn+86Z0SQSCS0ALedcu7u7q3W73dqVlRWtzWZTYbfbtWazRbu5uSnX3ZJr/335621cJFJkX84lDB23ZV/w81saAmQ0YBcXFywajbJ4PM6Emrucv/p/fHzMCJzZFhdZ0OUJu10uFgwGmXN9nXnsjvDy8jILBAJMgxtte3sbBIxIJIKdnV2KHTAWVuH3+0HsMfP+PSqKS2Dq6oLRaMTUP1NYs9pAh8G35YMEFMRKEIggAEEsBQsx4fV6hdfjET6fX1C6YnZ2ToRCIXEYORSFn30u7t29Jz65f1+YTCYRpfVy7/r6ulAM9/b2sEXo4XAYBAhK8yZxYufD/PwCqLbYDYXw1589uJOWhvT0dNzNzMTc+AQofYyPj0Nzc7PP51OAVC+4XC5K0QMPhdVixdzcPIgBNjY20PK8ARqNBklJSer7c/VTtLS0YGBg4Brw9PQU09PTWFhYUPUYHf0bw8PvMDIyQv1RDA4Oob29HROTExgw/YGXL16gsakJr1+9wlvDb6isrERPTw/+F0UCB0kUye7k5ITYuRS4w+7AssOBMUqrSldFII0wGn5HjNYdHh6q7+TQOzz49AHy8/MvGUplyYeg4uPg4EAByBQtZjOBmkGWwNDwMBoaGvCsrg5O2xLsS0tYpLBYLNCVluHH2lqkpqZCEz+JYWHeTDVzY3Nr64Nldj6k7oCZANdWV9Hb14eCggKUV5TjTZsB5EOsUU3lv2fE/DkdlpGR8THD7e2gEuU0HiewJRLDgqXFSxYTk5PY399HcUkJHhU+RDAQUMKRsVH+XTGampuRl5d3LYr8Rqgm5+fnaswYIzAbHJSux+PB2zdG5OTkIO1OGr764kswso/H64WfnPHt198gOzsbKSkp14BnZ2eKCd1frK6sYpHApOpTU1OYnZ0llQdRVlaGkseP0VT3E6xmYk81tFmtaKRxUVERSp88uVZZpkw3QYkilT46OkIsFlMHHRzsExO/Oqx/oJ/uVkLZ5gedTpn5ZG9f7ZdNQ2blxIzTi8IpTR6NHnGqKZdNCMEDfj8nX3LyI8/NzeVPa2q43+ninR0dPDk5mZeVlvId7wbvMpl4YWEh/+hxiJAgV83r3VCspJWcTpcqw8zMjEq5pvJ7nBPzrKws8qZOrW8zGJBJ11AC6mXQA6Hv7e3Vk9v1/X19+u7ubhVyfBXjY2OtuuoqfW1FZZups7O1qrpa/0tzc+uQsbNNT62+vr71P0wST3G7jKJRAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ete Hiver",
          "title": "Ete Hiver",
          "src": "/static/2e2cc30c4fe32d8a02daff795177e74c/a6d36/Ete-Hiver.png",
          "srcSet": ["/static/2e2cc30c4fe32d8a02daff795177e74c/222b7/Ete-Hiver.png 163w", "/static/2e2cc30c4fe32d8a02daff795177e74c/ff46a/Ete-Hiver.png 325w", "/static/2e2cc30c4fe32d8a02daff795177e74c/a6d36/Ete-Hiver.png 650w", "/static/2e2cc30c4fe32d8a02daff795177e74c/38af3/Ete-Hiver.png 894w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`On découvre alors trois groupes de caractéristiques de la clientèle (pas forcement exclusives) qui dominent les périodes:`}</p>
    <ul>
      <li parentName="ul">{`été: moyen-orient, afrique, usa, europe, 35-55 ans`}</li>
      <li parentName="ul">{`hiver: équipages aériens, <20-35 ans, femmes, locaux`}</li>
      <li parentName="ul">{`été & hiver: asie, touristes, réservations directes`}</li>
    </ul>
    <p>{`Pour chaque période, les cibles sont identifiées, il reste à étudier leur taille et potentiel pour allouer des objectifs et des budgets.`}</p>
    <h2>{`La morale de l'histoire`}</h2>
    <ol>
      <li parentName="ol">{`Les lignes de la table de données ont été transformées en `}<strong parentName="li">{`visualisations`}</strong>{` somme toute assez élémentaires: des diagrammes en barres bicolores pour coder au dessus ou en dessous de la moyenne. L'ordre des lignes a été réorganisé par `}<strong parentName="li">{`similarité visuelle`}</strong></li>
      <li parentName="ol">{`L'image a permis de prendre de la hauteur pour `}<strong parentName="li">{`voir les relations`}</strong>{` qui relient les différentes informations élémentaires qui étaient invisibles sous forme de nombres. `}</li>
      <li parentName="ol">{`Les nouvelles compréhensions acquises ont permis de prendre des `}<strong parentName="li">{`décisions pertinentes`}</strong>{` en vue des `}<strong parentName="li">{`objectifs à atteindre`}</strong>{` `}</li>
      <li parentName="ol">{`La structure (données et indicateurs) va servir également comme `}<strong parentName="li">{`tableau de bord de pilotage`}</strong>{` en mesurant l'efficacité des mesures en comparant N et N-1`}</li>
    </ol>
    <hr></hr>
    <h2>{`EPILOGUE`}</h2>
    <p>{`Cette histoire date de 1977 elle a été racontée par un certain `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Jacques_Bertin_%28cartographe%29"
      }}>{`Jacques BERTIN`}</a>{` considéré par beaucoup comme un des pères français de la visualisation de données (après Minard).`}</p>
    <p>{`Cette histoire figure dans les 20 premières pages d’un de ces livres. Pas dans son ouvrage le plus volumineux `}<a parentName="p" {...{
        "href": "https://visionscarto.net/la-semiologie-graphique-a-50-ans"
      }}>{`Sémiologie Graphique`}</a>{` de 1967, réédité plus récemment (2013) et traduit dans plusieurs langues et que je connais depuis cette date: c’est une bible très exhaustive (500 pages) délicieusement désuète dans la forme (les différents types de hachures en noir et blanc pour palier l’absence d’afficheur en couleur à l’époque par exemple) mais impressionnante et fondamentale sur le fond. A avoir obligatoirement dans sa bibliothèque. `}</p>
    <p>{`Non, cette histoire est le début de `}<strong parentName="p">{`l’autre`}</strong>{` publication de Jacques BERTIN
`}<a parentName="p" {...{
        "href": "https://www.amazon.fr/graphique-traitement-linformation/dp/2930601299"
      }}>{`LA GRAPHIQUE et le traitement graphique de l’information`}</a>{`. Initialement publiée en 1977 mais jamais réédité avant 2017. `}</p>
    <p>{`Remerciements éternels aux gentilles personnes qui me l'ont offert pour mon anniversaire. Si le début de ce billet est à la virgule près le texte authentique, j'ai pris certaines libertés avec le traitement de l'exemple pour être concis et plus simple à suivre. Lisez le livre pour la version longue et bien plus ... `}</p>
    <p>{`Les images ont été construites avec l’outil `}<a parentName="p" {...{
        "href": "https://aviz.fr/bertifier_app/"
      }}>{`Bertifier`}</a>{` issu du projet Aviz de l’INRIA. Cet outil permet de faire à la souris ce que Bertin réalisait avec du carton de la colle et des ciseaux!! `}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.079754601226995%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAACaklEQVQozw2RW1MSAQCF91f01EwPzdRLPtRMD2WNJeOMYcrkLTHFC4mjqOMFRENAkF25ihuw7IruBViW264ssIuACJmgFZVTTU9NT/2Q9gecc75zDpDL4K2G2LoQKnkmQ2OZGHpVL1x9KDTrheurSqtZUnQ/u33zRvvDtnGlYnl+UjMx3Hb3lnlTWxUTwHk5/fd3s1E9zqXwsN9p2VgkQh4uflDgIukYmoggJ3yMPoSzicOgD/RCRsvGwsz4AEPAv1pV4ISPngoMQyGQZd1pNWAwdABDkHnVqJ8z6uft5lVoWy95/fvz9VuztO8wuazrIktcVrkfnysAiXklsQd8p196m4ogCSJAhTwFlqiXM/VSKpsMB7zWn1+ql7Vcmae9oDEc2BUy+HVDvDhlgb4XsmXt9NRof2hvx2ldnxkfVL7qluADezaG9Asc9amevzzjS9koGwklcf/3RvG6WTwvJSv5GPC841F/b5d+aYZCvS7bxkCPTN75WDWiWJxTzaqVatVQkgp+POVElgy/d0hcQhrPMhhLB6W+QF+PzGRY8IKboGnNaTPotFNyWfv9e3fUE0Ow23IUcgksUROTxeOoYWUW2tahsJ3E3Azpq4pxoE/euaKdPgo6/O5taTDf7pbdvKbVjI2NKCS9bWtV5KhcGk/T6Jx6dHJU4bDqw34oHAAFjgC6u54iPntNYIosKRVjyEAiEmTj2CHiUg73ajUq6So2fhDaB8eGXqpe9+oWp2G3yQ0Z0jQCdDx5QGGeMh89E5iamCjn6WI2KkVlaFSy4FM4nzpiGZRCPZqJwTeD8rWFSdi15dzRpWLIf4qzTrqQk/oPAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "physical matrix lq",
          "title": "physical matrix lq",
          "src": "/static/85eb654118a0a908d601f51c6c58d90b/a6d36/physical_matrix_lq.png",
          "srcSet": ["/static/85eb654118a0a908d601f51c6c58d90b/222b7/physical_matrix_lq.png 163w", "/static/85eb654118a0a908d601f51c6c58d90b/ff46a/physical_matrix_lq.png 325w", "/static/85eb654118a0a908d601f51c6c58d90b/a6d36/physical_matrix_lq.png 650w", "/static/85eb654118a0a908d601f51c6c58d90b/e548f/physical_matrix_lq.png 975w", "/static/85eb654118a0a908d601f51c6c58d90b/85053/physical_matrix_lq.png 1225w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Merci à eux.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      